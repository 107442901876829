import Vue from "vue";

const treeMap = {
  namespace: true,
  state: {
    treeMapInstance: null
  },
  getters: {
    getTreeMapInstance: (state: any) => {
      return treeMap.state.treeMapInstance;
    }
  },
  mutations: {
    setTreeMapInstance: function(state: any, payload: any) {
      state.treeMapInstance = payload;
    }
  },
  actions: {}
};

export default treeMap;
