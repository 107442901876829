const explorePeriodData = {
    namespace: true,
    state: {
        periodData : null ,
        periodDetailData : null,
    },
    getters: {
        getPeriodData: (state: any) => {
            return state.periodData;
        },
        getPeriodDetailData: (state: any) => {
            return state.periodDetailData;
        }
    },
    mutations: {
        setPeriodData: function(state: any, payload: any) {
            state.periodData = payload;
        },
        setPeriodDetailData: function(state: any, payload: any) {
            state.periodDetailData = payload;
        },
    },
    actions: {
        // addPeriodDetailDataColor: function(context: any) {
        //     const periodDetailData = context.getters.getPeriodDetailData
        //     periodDetailData.forEach((wordInfo : object, idx : number)=> {
        //         // wordInfo.color = context.getters.getColors[idx]
        //     })
        //
        //     context.commit("setPeriodDetailData", periodDetailData);
        // },
    }
};

export default explorePeriodData;
