import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import VueCompositionApi from "@vue/composition-api";
import vuetify from "./plugins/vuetify";
import "./assets/js/carrotsearch.foamtree";
import VueCookies from "vue-cookies";
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);
Vue.use(VueCookies);
// Vue.use(VueCompositionApi);
Vue.config.productionTip = false;

export const vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
