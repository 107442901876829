const exploreContentData = {
    namespace: true,
    state: {
        categoryData: null,
        selectedDataSet : null,
    },
    getters: {
        getSelectedDataSet: (state: any) => {
            return state.selectedDataSet;
        },
        getCategoryData: (state: any) => {
            return state.categoryData;
        },
    },
    mutations: {
        setCategory: function(state: any, payload: any) {
            state.categoryData = payload;
        },
        setSelectedDataSet: function(state: any, payload: any) {
            state.selectedDataSet = payload;
        },
    },
    actions: {
        // addPeriodDetailDataColor: function(context: any) {
        //     const periodDetailData = context.getters.getPeriodDetailData
        //     periodDetailData.forEach((wordInfo : object, idx : number)=> {
        //         // wordInfo.color = context.getters.getColors[idx]
        //     })
        //
        //     context.commit("setPeriodDetailData", periodDetailData);
        // },
    }
};

export default exploreContentData;
