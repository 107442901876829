import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import App from "@/App.vue";
Vue.use(VueRouter);

const AuthUser = (to: object, from: object, next: any) => {
    if (Boolean(Vue.$cookies.get("loginToken"))) {
        next()
    } else {
        next('/login')
    }
}
const routes: Array<RouteConfig> = [
    {
        name: "Index",
        beforeEnter: AuthUser,
        path: "/",
        redirect: {
            name: 'ExploreContentFirstCategory',
            query: {
                section: '전체'
            }
        },
        component: App
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/login/Login.vue"),
    },
    {
        name: "ns",
        path: "/ns",
        redirect: {
            name: 'ExploreContent'
        },
        component: () => import("../views/Layout.vue"),
        children: [
            {
                path: "ExploreContent",
                name: "ExploreContent",
                redirect: {
                    name: 'ExploreContentFirstCategory',
                    query: {
                        section: '전체'
                    }
                },
                component: () => import("../views/explore_content/ExploreContent.vue"),
                props: true,
                children: [
                    {
                        path: "firstCategory",
                        name: "ExploreContentFirstCategory",
                        beforeEnter: AuthUser,
                        component: () => import("../views/explore_content/child/FirstCate.vue"),
                    },
                    {
                        path: "SelectSecondThirdCategory",
                        name: "ExploreContentSelectSecondThirdCategory",
                        props: true,
                        beforeEnter: AuthUser,
                        component: () => import("../views/explore_content/child/SecondCate.vue"),
                    },
                    {
                        path: "SelectFourCategory",
                        name: "ExploreContentSelectFourthCategory",
                        beforeEnter: AuthUser,
                        component: () => import("../views/explore_content/child/ThirdCate.vue"),
                    }
                ]
            },
            {
                path: "ExplorePeriod",
                name: "ExplorePeriod",
                redirect: {
                    name: 'ExplorePeriodPeriodTable',
                    query: {
                        period: '0'
                    },
                },
                component: () => import("../views/explore_period/ExplorePeriod.vue"),
                children: [
                    {
                        path: "periodTable",
                        name: "ExplorePeriodPeriodTable",
                        beforeEnter: AuthUser,
                        component: () => import("@/views/explore_period/child/PeriodTablePage.vue"),
                    },
                    {
                        path: "detailPeriodTable",
                        name: "ExplorePeriodDetailPeriodTable",
                        beforeEnter: AuthUser,
                        component: () => import("@/views/explore_period/child/DetailPeriodTablePage.vue"),
                    },
                    {
                        path: "timeTable",
                        name: "ExplorePeriodTimeTable",
                        beforeEnter: AuthUser,
                        component: () => import("@/views/explore_period/child/ComparisonTablePage.vue"),
                    },
                ]
            },
            {
                path: "Search",
                name: "Search",
                beforeEnter: AuthUser,
                component: () => import("../views/search/Search.vue"),
            },
            {
                path: "SearchResult",
                name: "SearchResult",
                beforeEnter: AuthUser,
                component: () => import("../views/search/Result.vue"),
            },
        ]
    },

];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
