import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import login from "./login";
import treeMap from "@/store/TreeMap";
import explorePeriod from "./explorePeriod"
import exploreContent from "./exploreContent"
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    sidebarMenu : {
      "" : [
        { title: "오늘의 요약", enTitle: "RealTimeAnalysis", icon: "fas fa-chart-line", goTo: "" },
        { title: "분류 탐색",  enTitle: "ExploreContent",icon: "fas fa-book", goTo: "ExploreContent" },
        { title: "시간 분석",  enTitle: "ExplorePeriod",icon: "fas fa-chart-bar", goTo: "ExplorePeriod"},
        { title: "상품 탐색",  enTitle: "ExploreTarget",icon: "fas fa-chart-pie", goTo: "" },
        { title: "검색",  enTitle: "Search",icon: "fas fa-search", goTo: "Search" }
      ],
    },
    dataSet : null,
    colors: [
      "#ff0029",
      "#377eb8",
      "#66a61e",
      "#984ea3",
      "#00d2d5",
      "#ff7f00",
      "#af8d00",
      "#7f80cd",
      "#b3e900",
      "#c42e60",
      "#a65628",
      "#f781bf",
      "#8dd3c7",
      "#bebada",
      "#fb8072",
      "#80b1d3",
      "#fdb462",
      "#fccde5",
      "#bc80bd",
      "#ffed6f",
      "#c4eaff",
      "#cf8c00",
      "#1b9e77",
      "#d95f02",
      "#e7298a",
      "#e6ab02",
      "#a6761d",
      "#0097ff",
      "#00d067",
      "#f43600",
      "#4ba93b",
      "#5779bb",
      "#927acc",
      "#97ee3f",
      "#bf3947",
      "#9f5b00",
      "#f48758",
      "#8caed6",
      "#f2b94f",
      "#eff26e",
      "#e43872",
      "#d9b100",
      "#9d7a00",
      "#698cff",
      "#d9d9d9",
      "#00d27e",
      "#d06800",
      "#009f82",
      "#c49200",
      "#cbe8ff",
      "#fecddf",
      "#c27eb6",
      "#8cd2ce",
      "#c4b8d9",
      "#f883b0",
      "#a49100",
      "#f48800",
      "#27d0df"
    ]
  },
  getters: {
    getSidebarMenu: state => {
      return state.sidebarMenu;
    },
    getDataSet: state => {
      return state.dataSet;
    },
    getColors: state => {
      return state.colors;
    }
  },
  mutations: {
    setDataSet: function(state, payload) {
      state.dataSet = payload;
    },

  },
  actions: {
    // getCategoryDataFromApi: async function(state, payload) {
    //   const url = payload;
    //   await axios.get(url).then(response => {
    //     this.commit("setCategory", response.data);
    //   });
    // },
  },
  modules: {
    login,
    exploreContent,
    treeMap,
    explorePeriod
  }
});

export default store;
