import Vue from "vue";

const login = {
  namespace: true,
  state: {},
  getters: {
    getLoginToken: (state: any) => {
      return `Bearer ${Vue.$cookies.get("loginToken")}`
    },
  },
  mutations: {},
  actions: {
    setLoginToken: function(state: any, payload: any) {
      Vue.$cookies.set( "loginToken", payload); // cookie
    },
  }
};

export default login;
