<template>
  <div>
    <router-view
      style="min-width: 750px; position: relative;"
      class="mini-main"
    ></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  mounted() {
  }
};
</script>

<style>
html,
body {
  /*height: 100%;*/
  background-color: #ebedef;
}

body {
}

i {
  margin: auto;
}

@media screen and (max-width: 992px) {
  .mini-sidebar {
    width: 65px !important;
    transition-duration: 1s !important;
  }

  .mini-sidebar p {
    display: none;
  }

  .mini-sidebar a {
    height: 70px !important;
    text-align: center !important;
    display: inline-block !important;
    width: 40px !important;
  }
  .mini-sidebar div {
    margin: 0 !important;
  }
  .mini-sidebar i {
    margin: auto !important;
    padding: 0 22px !important;
  }
  .mini-sidebar .menu-title {
    font-size: 12px !important;
    margin: 0 12px !important;
  }
  .mini-sidebar ul {
    margin: 0!important;
  }
  .mini-sidebar li {
    padding-top: 12px !important;
    transition-duration: 1s !important;
    border-top: 1px solid !important;
  }
  #header {
    width: calc(100% - 65px) !important;
    left: 65px !important;
  }
  #StepBar {
    left: 65px !important;
  }
  .mini-main {
    width: 100% !important;
  }

  #tree-map-tab > li {
    font-size: 15px !important;
    padding: 5px !important;
    font-weight: bold !important;
  }
  #period-tab > li {
    font-size: 15px !important;
    padding: 30px !important;
    font-weight: bold !important;
  }
  #content {
    left: 65px !important;
    width: calc(100%) !important;
  }

  .mini-sidebar > div:nth-child(1) > div {
    font-size: 15px !important;
    transition-duration: 1s !important;
  }

  .mini-sidebar > div:nth-child(2) > div {
    width: 65px !important;
    transition-duration: 1s !important;
  }
  #logo {
    display: block;
  }

  #explore-content-container, #header-title {
    width: calc(100% - 60px) !important;
    left: 60px!important;
  }
}
</style>
